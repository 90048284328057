import React from 'react'
import { Close } from '@mui/icons-material';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function DeleteParcelType({ open, handleClose }) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="view-order-dialog"
            fullWidth={true}
            maxWidth={"sm"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogTitle>
                Delete Parcel
            </DialogTitle>
            <DialogContent></DialogContent>
        </Dialog>
    )
}

export default DeleteParcelType