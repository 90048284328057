import React from 'react'
import { CalendarMonthOutlined, Close, LocalPhoneOutlined, LocalShippingOutlined, LocationOnOutlined, MoneyOutlined, PersonOutline } from '@mui/icons-material';
import { Box, Button, Chip, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide, Typography } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function ViewOrder({ open, handleClose, data }) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="view-order-dialog"
            fullWidth={true}
            maxWidth={"xl"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogTitle>
                Information for Order No.{" "}
            </DialogTitle>
            <DialogContent>
                <Box sx={{ display: "flex", mb: 1 }}>
                    <PersonOutline />
                    <Typography variant="body1" sx={{ ml: 2 }}>
                        Full Name: {data?.full_name}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                    <LocationOnOutlined />
                    <Typography variant="body1" sx={{ ml: 2 }}>
                        Shipping Address:{" "}
                        {`${data?.region}, ${data?.district}, ${data?.ward} ${data?.ward !== "" ? "," : ""} ${data?.street}`}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                    <LocalPhoneOutlined />
                    <Typography variant="body1" sx={{ ml: 2 }}>
                        Phone Number: {data?.phone_number}
                    </Typography>
                </Box>
                <Box sx={{ display: "flex", mb: 1 }}>
                    <CalendarMonthOutlined />
                    <Typography variant="body1" sx={{ ml: 2 }}>
                        Date: {data?.created_at}
                    </Typography>
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1
                    }}
                >
                    <LocalShippingOutlined />
                    <Typography variant="body1" sx={{ mx: 2 }}>
                        Order Status:
                    </Typography>
                    <Chip
                        color={
                            data?.order_status ===
                                "DELIVERED"
                                ? "success"
                                : "warning"
                        }
                        label={data?.order_status}
                        sx={{
                            width: 110,
                        }}
                    />
                </Box>
                <Box
                    sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1
                    }}
                >
                    <MoneyOutlined />
                    <Typography variant="body1" sx={{ mx: 2 }}>
                        Payment Status:
                    </Typography>
                    <Chip
                        color={
                            data?.payment_status ===
                                "COMPLETED"
                                ? "success"
                                : "warning"
                        }
                        label={data?.payment_status}
                        sx={{
                            width: 110,
                        }}
                    />
                </Box>
            </DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                    variant='contained'
                    size='small'
                >
                    Previos
                </Button>
                <Button
                    variant='contained'
                    size='small'
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewOrder