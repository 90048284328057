import React, { useCallback, useEffect, useState } from 'react'
import Dashboard from '../../dashboard/Dashboard'
import { Box, Button, Typography } from '@mui/material'
import CustomizedDataGrid from '../../dashboard/components/CustomizedDataGrid'
import CustomTabs from '../../components/CustomTabs'
import { ordersTableColumns } from '../../utils/table-columns'
import { authPostRequest } from '../../services/api-service'
import { getAllOrdersByPaginationUrl } from '../../services/urls'
import { useAuth } from '../../hooks/use-auth'
import { rowDirection } from '../../utils/styles'
import ViewOrder from './components/ViewOrder'
import EditOrder from './components/EditOrder'
import DeleteOrder from './components/DeleteOrder'

const orderStatus = [
    { label: "All", value: "" },
    { label: "Pending", value: "RECEIVED" },
    { label: "Need To Call", value: "NEEDTOCALLAGAIN" },
    { label: "Confirmed", value: "CONFIRMED" },
    { label: "Processing", value: "PROCESSING" },
    { label: "In Transit", value: "INTRANSIT" },
    { label: "Delivered", value: "DELIVERED" },
    { label: "Cancelled", value: "CANCELLED" },
    { label: "Returned", value: "RETURNED" },
    { label: "Refunded", value: "REFUNDED" },
    { label: "Duplicated", value: "DUPLICATED" },
    { label: "Out Of Stock", value: "OUTOFSTOCK" },
];

function Orders() {
    const auth = useAuth()
    const [ordersDetails, setOrdersDetails] = useState({})
    const [loading, setLoading] = useState(true)
    const [currentTab, setCurrentTab] = useState(orderStatus[0].value);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 20,
    });
    const [rowSelectionModel, setRowSelectionModel] = React.useState([]);
    const [viewOrder, setViewOrder] = React.useState(false);
    const [editOrder, setEditOrder] = React.useState(false);
    const [deleteOrder, setDeleteOrder] = React.useState(false);

    const onClickTab = (item) => {
        setCurrentTab(item.value);
    }

    const getOrders = useCallback(
        () => {
            setLoading(true)
            authPostRequest(
                getAllOrdersByPaginationUrl,
                {
                    query: auth.query,
                    sort: "id desc",
                    page: paginationModel.page + 1,
                    order_status: currentTab
                },
                (data) => {
                    setOrdersDetails(data)
                    setLoading(false)
                },
                (error) => {
                    setOrdersDetails({})
                    setLoading(false)
                }
            )
        }, [auth.query, currentTab, paginationModel.page]
    )

    useEffect(() => {
        getOrders()
    }, [getOrders])

    return (
        <Dashboard headerTitle='Orders'>
            {viewOrder &&
                <ViewOrder
                    open={viewOrder}
                    handleClose={() => setViewOrder(false)}
                />
            }
            {editOrder &&
                <EditOrder
                    open={editOrder}
                    handleClose={() => setEditOrder(false)}
                />
            }
            {deleteOrder &&
                <DeleteOrder
                    open={deleteOrder}
                    handleClose={() => setDeleteOrder(false)}
                />
            }
            <Box sx={{ width: '100%', maxWidth: { sm: '100%', md: '1700px' } }}>
                <Typography component="h2" variant="h6">
                    Orders
                </Typography>
                <CustomTabs
                    tabs={orderStatus}
                    currectTab={currentTab}
                    onClick={onClickTab}
                />
                {rowSelectionModel.length > 0 &&
                    <Box
                        sx={rowDirection}
                    >
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setViewOrder(true)
                            }}
                        >
                            View
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setEditOrder(true)
                            }}
                        >
                            Edit
                        </Button>
                        <Button
                            variant='contained'
                            size='small'
                            onClick={() => {
                                setDeleteOrder(true)
                            }}
                        >
                            Delete
                        </Button>
                    </Box>
                }
                <CustomizedDataGrid
                    loading={loading}
                    columns={ordersTableColumns}
                    data={ordersDetails}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    rowSelectionModel={rowSelectionModel}
                    setRowSelectionModel={setRowSelectionModel}
                />
            </Box>
        </Dashboard>
    )
}

export default Orders