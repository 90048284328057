import React from 'react'
import { Close } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from '@mui/material'

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditParcelType({ open, handleClose }) {
    return (
        <Dialog
            open={open}
            TransitionComponent={Transition}
            aria-describedby="view-order-dialog"
            fullWidth={true}
            maxWidth={"md"}
        >
            <DialogActions>
                <IconButton
                    edge="start"
                    color="inherit"
                    aria-label="close"
                    onClick={() => {
                        handleClose();
                    }}
                >
                    <Close />
                </IconButton>
            </DialogActions>
            <DialogTitle>
                Edit Parcel
            </DialogTitle>
            <DialogContent></DialogContent>
            <DialogActions sx={{ justifyContent: "space-between" }}>
                <Button
                    variant='contained'
                    size='small'
                >
                    Previos
                </Button>
                <Button
                    variant='contained'
                    size='small'
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default EditParcelType